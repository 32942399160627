<template >
    <div>
        <topnav></topnav>
        <div class="page-wrap">
            <div class="blog-index-wrap">
                <router-link v-for="blog in $local.blog.filter(b => b[$lang])" :key="blog._id" :to="`/blog/${blog._id}`"
                    class="blog-box">
                    <h3 v-if="$lang === 'en'" class="blog-box-head serif">{{ blog.title }}</h3>
                    <h3 v-else-if="$lang === 'fr'" class="blog-box-head serif">{{ blog.titleFrench || blog.title }}</h3>

                    <div class="blog-box-img">
                        <img :src="blog.image.imageURL" />
                    </div>
                    <div v-if="$lang === 'en'" class="blog-box-txt" v-html="blog.blurb || blog.body"></div>
                    <div v-else-if="$lang === 'fr'" class="blog-box-txt"
                        v-html="blog.blurbFrench || blog.bodyFrench || blog.blurb || blog.body"></div>
                    <p v-if="blog.date" class="blog-box-date">{{ new Date(blog.date).toLocaleString('en-US',
                        { year: '2-digit', month: 'short', day: '2-digit' }) }}</p>
                </router-link>
            </div>
        </div>
        <botfooter></botfooter>
    </div>
</template>

<script>
import Botfooter from '@/components/Botfooter.vue';
import Topnav from '@/components/Topnav.vue';
import { blog } from '@/data/localData.json';

export default {
    components: {
        Topnav,
        Botfooter
    },
    name: "Blog",
    computed: {
        $local() { return { blog } }
    }
};
</script>
